// Stimulus JS
import { application } from '@/src/global/stimulus-initializer';
import StimulusControllerResolver, {
  createViteGlobResolver
} from '@global/stimulus-resolver';

StimulusControllerResolver.install(
  application,
  createViteGlobResolver(import.meta.glob('./**/*-controller.{js,ts}'))
);
